<template>
  <div
    class="wish-btn"
    @click="handlerWishAdd"
  >
    <div class="wish-btn__content">
      <template v-if="isAddedWish">
        <img
          src="~/assets/img/svg/wish/wish-fill.svg"
          alt="wish"
        >
      </template>
      <template v-else>
        <img
          src="~/assets/img/svg/wish/wish.svg"
          alt="wish"
        >
      </template>
      <span>{{ isAddedWish ? 'В избранном' : 'В избранное' }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import useSitisWish from '#sitis/composables/useSitisWish';

const { productId } = defineProps<{
	productId: number;
}>();
const {
  getWishList,
  removeItemFromWishList,
  addToWishList,
  isInWish
} = useSitisWish();

const isAddedWish = computed(() => isInWish(productId));

const handlerWishAdd = async () => {
  isAddedWish.value ? await removeItemFromWishList(productId) : await addToWishList({ id: productId });
  await getWishList({ extensions: ['characteristics', 'options', 'modifications'] });
};

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.wish-btn {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 22px;
	background-color: var(--white);
	padding: 10px 15px;
	height: 50px;
	box-shadow: 0 4px 5px 0 #ddd2d2;

	.wish-btn__content {
		display: flex;
		align-items: center;
		gap: 10px;

		& > img {
			width: 20px;
			height: 20px;
		}

		& > span {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}
	}

}
</style>
